/* You can add global styles to this file, and also import other style files */
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import '../node_modules/@syncfusion/ej2-layouts/styles/material.css';
@import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Poppins:wght@200;400&display=swap');


body {
    margin: 0;
}